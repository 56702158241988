// src/components/PrivateRoute/PrivateRoute.js
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Notification from '../Notification/Notification';

const PrivateRoute = ({ element, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(null);
  const [userStatus, setUserStatus] = React.useState(null);
  const [showNotification, setShowNotification] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('/client/api/dashboard/check-session');
        setIsAuthenticated(response.data.isLoggedIn);
        const statusResponse = await axios.get('/client/api/dashboard/check-live-status');
        setUserStatus(statusResponse.data.status);
      } catch (error) {
        console.error('Error checking session:', error);
        setIsAuthenticated(false);
        setUserStatus(false);
      }
    };

    checkAuth();

    // Polling every 5 seconds for status update
    const intervalId = setInterval(checkAuth, 5000);

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [location]);

  React.useEffect(() => {
    if (isAuthenticated !== null && userStatus !== null) {
      if (!isAuthenticated) {
        window.location.href = '/v1/portal/csutc/dashboard/logout';
      } else if (userStatus === false && location.pathname !== '/v1/portal/csutc/dashboard/disapproved') {
        // Only show the notification and redirect if the user is not already on the disapproved page
        setShowNotification(true);
        setTimeout(() => {
          navigate('/v1/portal/csutc/dashboard/disapproved');
        }, 3000);
      } else if (userStatus === true && location.pathname === '/v1/portal/csutc/dashboard/disapproved') {
        // Redirect back to dashboard if user is accepted again
        navigate('/v1/portal/csutc/dashboard');
      }
    }
  }, [isAuthenticated, userStatus, location.pathname, navigate]);

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  if (isAuthenticated === null || userStatus === null) {
    return <div className="loader">Checking authentication...</div>;
  }

  return (
    <>
      {showNotification && (
        <Notification
          message="Due to some reason, your request is denied. You will be redirected shortly."
          onClose={handleCloseNotification}
        />
      )}
      {element}
    </>
  );
};

export default PrivateRoute;
