// src/components/Filters/CommonFilters.js
import React from 'react';
import { Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import FilterListIcon from '@mui/icons-material/FilterList';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    flexGrow: 1, // To ensure it takes available space
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const CommonFilters = ({
  filterApplied,
  filteredData,
  defaultSets,
  sortOrder,
  handleSortChange,
  showFilter,
  setShowFilter,
  appliedFilters,
  resetFilters,
}) => {
  const classes = useStyles();
  
  return (
    <div className="flex items-center gap-4">
      <span className="text-sm text-gray-600 mr-4">
        {filterApplied ? filteredData.length : defaultSets.length} items
      </span>

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>Sort by</InputLabel>
        <Select
          value={sortOrder}
          onChange={handleSortChange}
          label="Sort by"
          startAdornment={<SortIcon />}
        >
          <MenuItem value="new-to-old">New to Old</MenuItem>
          <MenuItem value="old-to-new">Old to New</MenuItem>
        </Select>
      </FormControl>

      <Button
        variant="outlined"
        onClick={() => setShowFilter(!showFilter)}
        className="flex items-center"
      >
        <FilterListIcon className="mr-2" />
        {showFilter
          ? `Hide Filters ${Object.keys(appliedFilters).length > 0 ? `(${Object.keys(appliedFilters).length})` : ''}`
          : `Filters By ${Object.keys(appliedFilters).length > 0 ? `(${Object.keys(appliedFilters).length})` : ''}`}
      </Button>
    </div>
  );
};

export default CommonFilters;
