// src/utils/IconUtility.js
import React from 'react';
import { SvgIcon } from '@mui/material';
import * as icons from '@mui/icons-material';

// Utility to get an icon component dynamically
const getIconComponent = (iconName) => {
    const IconComponent = icons[iconName];
    if (!IconComponent) {
      console.error(`Icon ${iconName} not found in @mui/icons-material`);
    }
    return IconComponent ? <IconComponent /> : null;
  };
  

export default getIconComponent;
