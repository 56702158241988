import { useEffect } from 'react';

const useFavicon = (faviconUrl) => {
  useEffect(() => {
    const link = document.querySelector('link[rel="icon"]') || document.createElement('link');
    link.rel = 'icon';
    link.href = faviconUrl;
    document.head.appendChild(link);
    
    // Force browser to reload favicon by updating URL
    link.href = faviconUrl + '?v=' + new Date().getTime(); 

    return () => {
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    };
  }, [faviconUrl]);
};

export default useFavicon;
