import React from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ImagePopup = ({ open, image, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <DialogContent
        className="relative p-0"
        onClick={onClose}
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', cursor: 'pointer' }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            color: 'white',
            zIndex: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
        <img
          src={image}
          alt="Detailed View"
          className="w-full h-auto block mx-auto"
          style={{ maxHeight: '80vh', objectFit: 'contain' }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ImagePopup;
