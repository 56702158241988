// src/components/Disapproved/Disapproved.js
import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Container, Box } from '@mui/material';
import { Alert, Snackbar } from '@mui/material';

const Disapproved = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [notification, setNotification] = React.useState('');

  const handleRefresh = async () => {
    setIsLoading(true);
    setErrorMessage('');
    setNotification('Checking your session...');

    try {
      const authResponse = await axios.get('/client/api/dashboard/check-session');
      if (!authResponse.data.isLoggedIn) {
        navigate('/v1/portal/csutc/dashboard/logout');
        return;
      }

      const statusResponse = await axios.get('/client/api/dashboard/check-live-status');
      if (statusResponse.data.status) {
        setNotification('You are back in!');
        setTimeout(() => {
          navigate('/v1/portal/csutc/dashboard');
        }, 2000); // Show the notification for 2 seconds before redirecting
      } else {
        setErrorMessage('Access still denied. Please contact support.');
        setNotification('');
      }
    } catch (error) {
      console.error('Error checking status:', error);
      setErrorMessage('Failed to refresh status. Please try again later.');
      setNotification('');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" className="text-center mt-16">
      <Box className="p-8 border border-gray-300 shadow-lg rounded-lg">
        <Typography variant="h4" className="font-semibold text-gray-800 mb-4">
          Your access has been denied
        </Typography>
        <Typography variant="body1" className="text-gray-600 mb-8">
          Please contact support for more information.
        </Typography>
        {errorMessage && (
          <Alert severity="error" className="mb-4">
            {errorMessage}
          </Alert>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleRefresh}
          disabled={isLoading}
          className="w-full py-2"
        >
          {isLoading ? 'Checking...' : 'Refresh Status'}
        </Button>
        <Snackbar
          open={!!notification}
          message={notification}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={() => setNotification('')}
          autoHideDuration={3000} // Auto hide after 3 seconds
        />
      </Box>
    </Container>
  );
};

export default Disapproved;
