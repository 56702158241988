// src/components/ImageSkeletonLoader.js
import React from 'react';
import ContentLoader from 'react-content-loader';
import './ImageSkeletonLoader.css'; // Import the CSS file

const ImageSkeletonLoader = (props) => (
  <ContentLoader 
    className="blinking-loader" // Apply the CSS class here
    speed={2}
    width={400}
    height={200}
    viewBox="0 0 400 200"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="4" ry="4" width="400" height="200" />
  </ContentLoader>
);

export default ImageSkeletonLoader;
