// src/hooks/FilePopup.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const FilePopup = ({ open, onClose, fileUrl }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: '12px',
          padding: '16px',
          boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
        },
      }}
      BackdropProps={{
        style: {
          backdropFilter: 'blur(6px)',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        },
      }}
    >
      <DialogTitle>
        View File
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers style={{ padding: 0 }}>
        {fileUrl ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80vh',
              overflow: 'auto',
              backgroundColor: '#f9f9f9',
            }}
          >
            <img
              src={fileUrl}
              alt="file content"
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                borderRadius: '8px',
                objectFit: 'contain',
              }}
            />
          </Box>
        ) : (
          <Typography variant="body1" style={{ padding: '16px' }}>
            No file available
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FilePopup;
