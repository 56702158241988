// src/components/RedirectHandler/RedirectHandler.js
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const RedirectHandler = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [redirectTo, setRedirectTo] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('/client/api/dashboard/check-session');
        setIsAuthenticated(response.data.isLoggedIn);
        const statusResponse = await axios.get('/client/api/dashboard/check-live-status');
        setUserStatus(statusResponse.data.status);
      } catch (error) {
        console.error('Error checking session:', error);
        setIsAuthenticated(false);
        setUserStatus(false);
      } finally {
        setInitialLoad(false);
      }
    };

    checkAuth();
  }, [location]);

  useEffect(() => {
    if (!initialLoad) {
      if (!isAuthenticated) {
        window.location.href = '/account-center/signin-identifier'; // Redirect with reload
      } else if (userStatus === false) {
        setRedirectTo('/v1/portal/csutc/dashboard/disapproved');
      } else {
        setRedirectTo('/v1/portal/csutc/dashboard');
      }
    }
  }, [isAuthenticated, userStatus, initialLoad]);

  if (!isAuthenticated && !initialLoad) {
    // Show loader only if not authenticated
    return <div className="loader">Checking authentication...</div>;
  }

  if (redirectTo) {
    return <Navigate to={redirectTo} />;
  }

  return null; // Return nothing as redirection is handled in the useEffect
};

export default RedirectHandler;
