import { useEffect } from 'react';
import { saveRecentlyViewed } from '../utils/saveRecentlyViewedCards';

const useRecentlyViewed = () => {
  // Function to handle saving a product to recently viewed
  const handleRecentlyViewed = (set) => {
    const product = {
      uuid: set.uuid,
      type: set.type,
      categoryLink: set.categoryLink,
      cfdSKU: set.cfdSKU,
      cfdImage: set.cfdImage,
      creationDate: set.creationDate,
    };

    // Save to the backend using the utility function
    saveRecentlyViewed(product);
  };

  return { handleRecentlyViewed };
};

export default useRecentlyViewed;
