
// src/utils/wishlistUtils.js
import axios from 'axios';

export const saveToWishlist = async (product) => {
    try {
      const response = await axios.post('/client/api/dashboard/wishlist', product);
      return response.data; // Return the saved item with `creationDate`
    } catch (error) {
      console.error('Error saving to wishlist:', error);
      return null;
    }
  };

export const removeFromWishlist = async (uuid) => {
  try {
    await axios.delete(`/client/api/dashboard/wishlist/${uuid}`);
  } catch (error) {
    console.error('Error removing from wishlist:', error);
  }
};

export const fetchWishlist = async () => {
  try {
    const response = await axios.get('/client/api/dashboard/wishlist');
    return response.data;
  } catch (error) {
    console.error('Error fetching wishlist:', error);
    return [];
  }
};
