// src/components/Filters/FilterSidebar.js
import React, { useState,  useEffect, useRef } from 'react';
import { Button, FormControl, InputLabel, Select, MenuItem, Drawer, TextField, Popover, IconButton  } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowDropDown, ArrowDropUp, Close as CloseIcon, FilterList as FilterIcon } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 320,
    padding: theme.spacing(3),
    backgroundColor: '#f9f9f9',
  },
  formControl: {
    minWidth: 270,
    marginBottom: theme.spacing(2),
  },
  applyButton: {
    marginTop: theme.spacing(3),
    backgroundColor: '#0171BB',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#005f9e',
    },
    width: '100%',
    padding: theme.spacing(1.5),
    borderRadius: '8px',
  },
  resetButton: {
    marginTop: theme.spacing(2),
    backgroundColor: '#ff4d4d',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#cc0000',
    },
    width: '100%',
    padding: theme.spacing(1.5),
    borderRadius: '8px',
  },
  closeButton: {
    marginTop: theme.spacing(2),
    width: '100%',
    padding: theme.spacing(1.5),
  },
  dropdown: {
    maxHeight: 270,
    width: 270,
    overflowY: 'auto',
  },

  dropdownSearch: {
    width: 300,
    maxHeight: 270,
    overflowY: 'auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  sidebarTitle: {
    fontSize: '18px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
  },
  iconLeft: {
    marginRight: theme.spacing(1),
  },
  searchField: {
    padding: theme.spacing(1),
    width: '100%'
  },
  buttonSelect: {
    height: '56px', // Match the height of the Select component
    width: '100%',
    padding: '10px 14px',
    border: '1px solid #ccc',
    fontSize: '1rem',
     color: '#888',
    textTransform: 'capitalize',
    borderRadius: '4px',
    textAlign: 'left',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  buttonSelectIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: '0px', // Space between icons
    color: '#888',
    marginRight: '-8px',
    '&:hover': {
      color: '#000',
    },
  },
  buttonSelectIconButton: {
    marginRight: '-11px',
  },
  clearIcon: {
    cursor: 'pointer',
    color: '#888',
    marginRight: '8px',
    '&:hover': {
      color: '#000',
    },
  },  
}));

const FilterSidebar = ({ open, onClose, filters, setFilters, availableFilters, applyFilters, resetFilters, fields, storageKey }) => {
    const classes = useStyles();
    const [categorySearch, setCategorySearch] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const inputRef = useRef(null);
  
    // Map fields array to an object for easy label lookup
    const labelMap = fields.reduce((acc, item) => {
        acc[item.field] = item.label;
        return acc;
    }, {});

    const handleOpenDropdown = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleCloseDropdown = () => {
      setAnchorEl(null);
    };

  // Load filters from localStorage on component mount using the specific storage key
  const isInitialRender = useRef(true);
  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem(storageKey));
    if (savedFilters) {
      setFilters(savedFilters);
    }
  }, [setFilters, storageKey]);

  // Apply filters on initial load if filters are present
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      if (Object.keys(filters).length > 0) {
        applyFilters();
      }
      return;
    }

    // Save filters to localStorage after initial load using the specific storage key
    localStorage.setItem(storageKey, JSON.stringify(filters));
  }, [filters, applyFilters, storageKey]);
  

  
    const handleCategoryChange = (value) => {
      setFilters((prev) => ({
        ...prev,
        categories: value,
      }));
      handleCloseDropdown();
    };
  
  // Reset and clear the specific filter option
   const clearFilterSelection = (filterKey) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      delete updatedFilters[filterKey]; // Remove the filter key entirely
      return updatedFilters;
    });
  };

    const isDropdownOpen = Boolean(anchorEl);
  
    return (
      <Drawer anchor="right" open={open} onClose={onClose}>
        <div className={classes.drawer}>
        <div className={classes.header}>
            {/* Left Icon and Title */}
            <h2 className={classes.sidebarTitle}>
              <FilterIcon className={classes.iconLeft} /> Filters By
            </h2>
            
            {/* Close Icon Button */}
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
  
       {/* Categories Filter with Popover */}
         <FormControl className={classes.formControl}>
                <Button
                variant="outlined"
                onClick={handleOpenDropdown}
                ref={inputRef}
                className={classes.buttonSelect}
                fullWidth
                classes={{ endIcon: classes.buttonSelectIcon }}
                endIcon={
                <>
                    {filters.categories && (
                    <IconButton
                        size="small"
                        className={classes.buttonSelectIconButton}
                        onClick={(e) => {
                        e.stopPropagation(); // Prevent dropdown from opening when clearing
                        clearFilterSelection('categories');
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                    )}
                    {isDropdownOpen ? (
                    <ArrowDropUp style={{ fontSize: 24 }} />
                    ) : (
                    <ArrowDropDown style={{ fontSize: 24 }} />
                    )}
                </>
                }
            >
                {filters.categories || 'Select CFD Type'}
            </Button>

          <Popover
            open={isDropdownOpen}
            anchorEl={anchorEl}
            onClose={handleCloseDropdown}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{ width: inputRef.current ? inputRef.current.offsetWidth + 80 : 320 }} // Adjust width
          >
            <div className={classes.dropdownSearch}>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search CFD Type"
                value={categorySearch}
                onChange={(e) => setCategorySearch(e.target.value)}
                className={classes.searchField}
                onClick={(e) => e.stopPropagation()}
                style={{ width: '100%' }} // Set width to 100%
              />

              {availableFilters.categories
                .filter((option) =>
                  option.toLowerCase().includes(categorySearch.toLowerCase())
                )
                .map((option) => (
                  <MenuItem
                    key={option}
                    onClick={() => handleCategoryChange(option)}
                  >
                    {option}
                  </MenuItem>
                ))}
            </div>
          </Popover>
        </FormControl>
  
          {/* Other Filters using Select */}
          {Object.keys(availableFilters).map((filterKey) => {
            if (filterKey === 'categories') return null; // Skip categories since it's already handled
  
            return (
                <FormControl key={filterKey} className={classes.formControl} variant="outlined" fullWidth>
                <InputLabel>{`Select ${labelMap[filterKey] || filterKey}`}</InputLabel>
                <Select
                  value={filters[filterKey] || ''}
                  onChange={(event) =>
                    setFilters((prev) => ({
                      ...prev,
                      [filterKey]: event.target.value,
                    }))
                  }
                  label={`Select ${labelMap[filterKey] || filterKey}`}
                  endAdornment={
                    filters[filterKey] && (
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          clearFilterSelection(filterKey);
                        }}
                        className={classes.clearIcon}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )
                  }
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        overflowY: 'auto',
                      },
                    },
                  }}
                >
                  {availableFilters[filterKey].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>                         
            );
          })}
  
          <Button
            variant="contained"
            className={classes.applyButton}
            onClick={() => {
              applyFilters();
              onClose();
            }}
          >
            Apply Filters
          </Button>
  
          <Button variant="contained" className={classes.resetButton} onClick={resetFilters}>
            Reset Filters
          </Button>

        <Button variant="outlined" onClick={onClose} className={classes.closeButton}>
          Close
        </Button>
      </div>
    </Drawer>
  );
};

export default FilterSidebar;
