// src/components/Header/Header.js
import React, { useState, useEffect } from 'react';
import { Avatar, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import ConfirmationPopup from '../ConfirmationPopup/ConfirmationPopup';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ProfileIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import "./Header.css";
import { useTitle } from '../Contexts/TitleContext';

const Header = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [uuid, setUuid] = useState('');
  const { title } = useTitle(); // Get the current title

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('/client/api/dashboard/user-profile-header');
        console.log('Fetched user data Header:', response.data);
        const { profileImage, avatar, firstName, lastName, email } = response.data;
        setProfileImage(profileImage);
        setAvatar(avatar);
        setFirstName(firstName);
        setLastName(lastName);
        setEmail(email);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleLogoutConfirmation = async () => {
    setIsPopupOpen(false);
    try {
      localStorage.setItem('logoutRequested', 'true');
      const response = await axios.get('/client/api/dashboard/logout');
      const token = response.data.uuid;
      setUuid(response.data.uuid);
      localStorage.removeItem('authToken');
      navigate(`/v1/portal/csutc/dashboard/logout/?token=${token}`, { replace: true });
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const handleLogoutClick = () => {
    setIsPopupOpen(true);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsClick = () => {
    handleProfileMenuClose();
    navigate('account-settings/profile');
  };

  return (
    <header className="bg-white text-black p-4 shadow-md fixed top-0 left-64 right-0 z-10">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-xl font-bold">{title}</h1>
        <div className="relative">
          <Tooltip
            title={
              <div className="bg-black text-white p-2 rounded-md">
                <Typography variant="body2">{firstName} {lastName}</Typography>
                <Typography variant="body2">{email}</Typography>
              </div>
            }
            placement="right"
            arrow
          >
            <Avatar
              alt="User Avatar"
              src={profileImage || undefined}
              className="cursor-pointer"
              onClick={handleProfileMenuOpen}
            >
              {!profileImage && avatar}
            </Avatar>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleProfileMenuClose}
            MenuListProps={{ onMouseLeave: handleProfileMenuClose }}
          >
            <div className="flex items-center p-4 border-b">
              <Avatar
                alt="User Avatar"
                src={profileImage || undefined}
                className="mr-2"
              >
                {!profileImage && avatar}
              </Avatar>
              <div>
                <Typography variant="body1">{firstName} {lastName}</Typography>
                <Typography variant="body2" color="textSecondary">{email}</Typography>
              </div>
            </div>
            <MenuItem onClick={handleSettingsClick}>
              <ProfileIcon className="mr-2" />
             My Profile
            </MenuItem>
            <MenuItem onClick={handleLogoutClick}>
              <LogoutIcon className="mr-2" />
              Logout
            </MenuItem>
          </Menu>
        </div>
      </div>

      <ConfirmationPopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onConfirm={handleLogoutConfirmation}
      />
    </header>
  );
};

export default Header;


