import React, { useState } from 'react';
import { Button, Select, MenuItem, FormControl, InputLabel, Checkbox, ListItemText } from '@mui/material';
import { toast } from 'react-toastify';

const DeleteOptionsPopup = ({ categories, onDeleteAll, onDeleteByCategory, onCancel }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [deleteMode, setDeleteMode] = useState(null); // 'all' or 'category'

  const handleCategoryChange = (event) => {
    setSelectedCategories(event.target.value);
  };

  // Utility function to format category names
const formatCategory = (category) => {
    if (!category) return '';
    // Replace dashes with spaces, capitalize words, and add " Sets" at the end
    return category
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
        <h2 className="text-xl mb-4">Delete Recently Viewed Items</h2>

        {/* Delete Options */}
        <div className="flex flex-col space-y-4">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setDeleteMode('all');
              onDeleteAll();
            }}
          >
            Delete All
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => setDeleteMode('category')}
          >
            Delete By Category
          </Button>

          {/* Multi-select dropdown for categories */}
          {deleteMode === 'category' && (
            <FormControl fullWidth className="mt-4">
              <InputLabel>Select Categories</InputLabel>
              <Select
                multiple
                value={selectedCategories}
                onChange={handleCategoryChange}
                renderValue={(selected) => selected.map(formatCategory).join(', ')}
              >
               {categories.map((category, index) => (
                <MenuItem key={index} value={category}>
                    <Checkbox checked={selectedCategories.includes(category)} />
                    <ListItemText primary={formatCategory(category)} />
                </MenuItem>
                ))}
              </Select>
              <Button
                variant="contained"
                color="secondary"
                className="mt-4"
                onClick={() => {
                    onDeleteByCategory(selectedCategories);
                    toast.success('Deleting selected categories...');
                }}
                disabled={selectedCategories.length === 0}
                >
                 Confirm Delete
             </Button>
            </FormControl>
          )}
        </div>

        <Button onClick={onCancel} className="mt-6">
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default DeleteOptionsPopup;
