// src/components/Filters/AppliedFilters.js
import React, { useEffect } from 'react';

const AppliedFilters = ({
  appliedFilters,
  setFilters,
  setAppliedFilters,
  resetFilters,
  fields,
  storageKey
}) => {
  // Map fields array to an object for easy label lookup
  const labelMap = fields.reduce((acc, item) => {
    acc[item.field] = item.label;
    return acc;
  }, {});

  // Load applied filters from localStorage on component mount using the specific storage key
  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem(storageKey));
    if (savedFilters) {
      setAppliedFilters(savedFilters);
    }
  }, [setAppliedFilters, storageKey]);

  // Handler for removing a specific filter
  const handleRemoveFilter = (filterKey) => {
    // Update the filters state
    setFilters((prev) => {
      const updatedFilters = { ...prev };
      delete updatedFilters[filterKey];
      return updatedFilters;
    });

    // Update the appliedFilters state
    setAppliedFilters((prev) => {
      const updatedAppliedFilters = { ...prev };
      delete updatedAppliedFilters[filterKey];
      return updatedAppliedFilters;
    });

    // Update localStorage using the specific storage key
    const updatedLocalStorageFilters = { ...appliedFilters };
    delete updatedLocalStorageFilters[filterKey];
    localStorage.setItem(storageKey, JSON.stringify(updatedLocalStorageFilters));

    // Reset filters if no filters are left
    if (Object.keys(updatedLocalStorageFilters).length === 0) {
      resetFilters();
      localStorage.removeItem(storageKey);
    }
  };

  // Handler for clearing all filters
  const handleClearAllFilters = () => {
    resetFilters();
    localStorage.removeItem(storageKey);
  };

  return (
    <div className="flex flex-wrap gap-4 mb-6 items-center">
      {/* Display applied filters */}
      {Object.entries(appliedFilters).map(([filterKey, filterValue]) => (
        filterValue && (
          <div
            key={filterKey}
            className="bg-blue-100 text-blue-700 px-3 py-1 rounded-lg flex items-center space-x-2"
          >
            <span className="font-semibold">{`${labelMap[filterKey] || filterKey}:`}</span>
            <span>{filterValue}</span>
            <button
              className="text-red-500 hover:text-red-700"
              onClick={() => handleRemoveFilter(filterKey)}
            >
              ✖
            </button>
          </div>
        )
      ))}

      {/* Clear Filters Button */}
      {Object.keys(appliedFilters).length > 0 && (
        <button
          className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700"
          onClick={handleClearAllFilters}
        >
          Clear Filters
        </button>
      )}
    </div>
  );
};

export default AppliedFilters;
