// src/components/StickyBottomPopup/StickyBottomPopup.js
import React, { useState, useEffect } from 'react';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import CookiePolicyModal from './CookiePolicyModal';

const StickyBottomPopup = () => {
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [isCookieOpen, setIsCookieOpen] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(true);

  useEffect(() => {
    // Check if the user has already clicked "OKAY"
    const hasAcceptedCookies = localStorage.getItem('cookiesAccepted');
    if (hasAcceptedCookies) {
      setIsPopupVisible(false);
    }
  }, []);

  const handleOkayClick = () => {
    // Set a flag in localStorage so the popup won't show again
    localStorage.setItem('cookiesAccepted', 'true');
    setIsPopupVisible(false);
  };

  if (!isPopupVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-opacity-60 backdrop-blur-lg bg-gray-900 p-4 z-50 flex justify-center">
      <div className="max-w-6xl w-full grid grid-cols-1 md:grid-cols-2 items-center text-white">
        {/* Left side with text */}
        <div className="text-center md:text-left p-2">
          <p className="text-sm">
            Foxx Bioprocess Custom SUT Catalog uses cookies to provide necessary site functionality and improve your experience. By using our website, you agree to our&nbsp;
            <button
              className="underline text-blue-400"
              onClick={() => setIsPrivacyOpen(true)}
            >
              privacy policy
            </button> and our&nbsp;
            <button
              className="underline text-blue-400"
              onClick={() => setIsCookieOpen(true)}
            >
              cookie policy
            </button>.
          </p>
        </div>
        
        {/* Right side with OKAY button */}
        <div className="flex justify-center md:justify-end p-2">
          <button
            onClick={handleOkayClick}
            className="bg-[#0171BB] hover:bg-[#015A9A] text-white font-bold py-2 px-6 rounded"
          >
            OK
          </button>
        </div>
      </div>

      {/* Privacy and Cookie Modals */}
      <PrivacyPolicyModal isOpen={isPrivacyOpen} onClose={() => setIsPrivacyOpen(false)} />
      <CookiePolicyModal isOpen={isCookieOpen} onClose={() => setIsCookieOpen(false)} />
    </div>
  );
};

export default StickyBottomPopup;

