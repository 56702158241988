import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, TextField, Container, Typography, Paper, Box, Grid, CircularProgress, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns'; // Import the format function
import CameraAltIcon from '@mui/icons-material/CameraAlt'; // Import Camera icon
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import Checkmark icon
import { useTitle } from '../Contexts/TitleContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    backgroundColor: '#ffffff',
  },
  paper: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  profileImage: {
    width: '100px',
    height: '100px',
    borderRadius: '45%',
    objectFit: 'cover',
    marginBottom: theme.spacing(2),
    border: '3px solid #eeeeee',
    boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
    position: 'relative', // Add relative positioning for the checkmark icon
  },
  uploadButton: {
    marginTop: theme.spacing(2),
    width: '40px',
    height: '40px',
    borderRadius: '50%',
  },
  cameraIcon: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    transform: 'translate(50%, 50%)',
    backgroundColor: '#ffffff',
    borderRadius: '50%',
    border: '2px solid #ffffff',
    boxShadow: '0px 0px 5px rgba(0,0,0,0.3)',
  },
  checkmarkIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(50%, -50%)',
    color: '#4caf50', // Green color
    backgroundColor: '#ffffff',
    borderRadius: '50%',
    border: '2px solid #ffffff',
    boxShadow: '0px 0px 5px rgba(0,0,0,0.3)',
  },
 
  buttonSave: {
    marginRight: theme.spacing(2), // Adds space specifically to the left side of the Cancel button
  },
}));

const UserProfile = () => {
  const classes = useStyles();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState('');
  const [updateTimestamp, setUpdateTimestamp] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [fileInputKey, setFileInputKey] = useState(Date.now());


  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle('Account Settings');
  }, [setTitle]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/client/api/dashboard/user-profile');
        console.log("Fetched user data:", response.data); // Log the fetched data
        setUserData(response.data);
        setFormData(response.data);
        setProfilePicture(response.data.profileImage);
      } catch (error) {
        console.error('Error fetching user data:', error); // Log any errors
        setError('Failed to fetch user data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    setFormData(userData);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("Submitting form data:", formData); // Log form data before sending
      const response = await axios.post(`/client/api/dashboard/user-profile/update`, formData); // Update the endpoint
      console.log("Update response data:", response.data); // Log the response data
      setUserData(response.data);
      setUpdateTimestamp(new Date().toLocaleString()); // Update timestamp
      setEditMode(false);
    } catch (error) {
      console.error('Error updating user data:', error); // Log any errors
      setError('Failed to update user data.');
    }
  };

  const handleProfilePictureChange = async (e) => {
    const formData = new FormData();
    formData.append('profileImage', e.target.files[0]);

    try {
      const response = await axios.post(`/client/api/dashboard/user-profile/upload-profile-picture`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setUserData(response.data);
      setProfilePicture(response.data.profileImage);
      setFileInputKey(Date.now()); // Reset file input
    } catch (error) {
      setError('Failed to upload profile picture.');
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return format(date, "d MMMM yyyy 'at' hh:mm a"); // Format date and time
  };

  if (loading) {
    return (
      <Box className="flex justify-center items-center h-screen">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box className="flex justify-center items-center h-screen">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Container className={classes.root} maxWidth="md">
       {!editMode && (
            <Typography variant="body1" gutterBottom ml={2}>
              {updateTimestamp ? (
                <>
                  <strong>Last Updated:</strong> {formatDate(updateTimestamp)}
                </>
              ) : (
                <>
                  <strong>Last Updated:</strong> {formatDate(userData.lastUpdated)}
                </>
              )}
            </Typography>
          )}
      <Paper className={classes.paper}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" mb={2}>
          <Typography variant="h5" gutterBottom>
            User Profile
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center" mb={10}>
          <Box position="relative">
            <img
              src={profilePicture || 'https://res.cloudinary.com/dtsg4pipk/image/upload/v1724155542/profile_picture_placeholder_image_ivb6js.png'}
              alt="Profile"
              className={classes.profileImage}
            />
            <input
              key={fileInputKey}
              type="file"
              accept="image/*"
              id="profilePictureUpload"
              style={{ display: 'none' }}
              onChange={handleProfilePictureChange}
            />
            <label htmlFor="profilePictureUpload">
              <IconButton
                color="primary"
                component="span"
                className={classes.uploadButton}
                style={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  left: 0,
                  transform: 'translate(82%, 50%)',
                  backgroundColor: '#ffffff',
                  borderRadius: '50%',
                  border: '2px solid #ffffff',
                  boxShadow: '0px 0px 5px rgba(0,0,0,0.3)',
                }}
              >
                <CameraAltIcon />
              </IconButton>
            </label>
            {profilePicture && (
              <CheckCircleIcon className={classes.checkmarkIcon} />
            )}
          </Box>
        </Box>

        {!editMode ? (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1"><strong>First Name:</strong> {userData.firstName}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1"><strong>Last Name:</strong> {userData.lastName}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1"><strong>Email:</strong> {userData.email}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1"><strong>Job Title:</strong> {userData.jobTitle}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1"><strong>Company:</strong> {userData.company}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1"><strong>Department:</strong> {userData.department}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1"><strong>Street Address:</strong> {userData.streetAddress}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1"><strong>Postal Code:</strong> {userData.postalCode}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1"><strong>Country:</strong> {userData.selectedCountry}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1"><strong>State:</strong> {userData.selectedState}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1"><strong>Phone Number:</strong> {userData.phoneNumber}</Typography>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleEdit}
            >
              Edit
            </Button>
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstName"
                  label="First Name"
                  value={formData.firstName || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="lastName"
                  label="Last Name"
                  value={formData.lastName || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  label="Email"
                  value={formData.email || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="jobTitle"
                  label="Job Title"
                  value={formData.jobTitle || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="company"
                  label="Company"
                  value={formData.company || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="department"
                  label="Department"
                  value={formData.department || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="streetAddress"
                  label="Street Address"
                  value={formData.streetAddress || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="postalCode"
                  label="Postal Code"
                  value={formData.postalCode || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="selectedCountry"
                  label="Country"
                  value={formData.selectedCountry || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="selectedState"
                  label="State"
                  value={formData.selectedState || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="phoneNumber"
                  label="Phone Number"
                  value={formData.phoneNumber || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={`${classes.button} ${classes.buttonSave}`}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </form>
        )}
      </Paper>
    </Container>
  );
};

export default UserProfile;
