// src/components/Filters/useFilterAndSort.js
import { useState, useEffect, useRef } from 'react';

const useFilterAndSort = (initialData, fields, storageKey) => {
  const [data, setData] = useState(initialData);
  const [filters, setFilters] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});
  const [filterApplied, setFilterApplied] = useState(false);
  const [sortOrder, setSortOrder] = useState('new-to-old');
  const [filteredData, setFilteredData] = useState(initialData);
  const isInitialRender = useRef(true);

  // Function to apply filters
  const applyFilters = () => {
    const filtered = data.filter((set) =>
      Object.keys(filters).every((filterKey) => {
        const filterValues = filters[filterKey];
        const selectedValues = Array.isArray(filterValues) ? filterValues : [filterValues];

        if (!selectedValues || selectedValues.length === 0) return true;

        if (filterKey === 'categories') {
          const setCategories = Array.isArray(set.categories)
            ? set.categories
            : typeof set.categories === 'string'
            ? set.categories.split(',').map((cat) => cat.trim())
            : [];

          return selectedValues.some((selectedCategory) =>
            setCategories.some((category) =>
              category.toLowerCase().includes(selectedCategory.toLowerCase())
            )
          );
        } else {
          const fieldValue = set[filterKey];
          if (fieldValue) {
            const fieldValues = fieldValue.includes(',')
              ? fieldValue.split(',').map((v) => v.trim())
              : [fieldValue];
            return selectedValues.some((val) => fieldValues.includes(val));
          }
          return false;
        }
      })
    );

    setAppliedFilters(filters);
    setFilteredData(filtered);
    setFilterApplied(true);
    localStorage.setItem(storageKey, JSON.stringify(filters));
  };

  // Function to reset filters
  const resetFilters = () => {
    setFilters({});
    setAppliedFilters({});
    setFilteredData(data);
    setFilterApplied(false);
    localStorage.removeItem(storageKey);
  };

  // Load filters from localStorage on component mount
  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem(storageKey));
    if (savedFilters) {
      setFilters(savedFilters);
      setAppliedFilters(savedFilters);
    }
  }, [storageKey]);

  // Apply filters on initial load if filters are present
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      if (Object.keys(filters).length > 0) {
        applyFilters(); // Apply filters only on initial load if filters are present
      }
      return;
    }

    // Save filters to localStorage after initial load using the specific storage key
    localStorage.setItem(storageKey, JSON.stringify(filters));
  }, [filters, storageKey]);

  useEffect(() => {
    // Set initial data
    setData(initialData);
    setFilteredData(initialData);
  }, [initialData]);

  const extractFilterOptions = (data) => {
    const filterOptions = {};

    fields.forEach((field) => {
      const fieldName = field.field;

      // Exclude 'cfdSKU' from filter options
      if (fieldName === 'cfdSKU') return;

      if (fieldName === 'categories') {
        const uniqueCategories = Array.from(
          new Set(
            data.flatMap((item) => {
              if (Array.isArray(item.categories)) {
                return item.categories.flatMap((cat) => cat.split(',').map((c) => c.trim()));
              } else if (typeof item.categories === 'string') {
                return item.categories.split(',').map((cat) => cat.trim());
              }
              return [];
            })
          )
        );
        filterOptions[fieldName] = uniqueCategories;
      } else {
        const uniqueValues = Array.from(
          new Set(
            data
              .map((item) => item[fieldName])
              .filter(Boolean)
              .flatMap((value) =>
                value.includes(',') ? value.split(',').map((v) => v.trim()) : [value]
              )
          )
        );
        filterOptions[fieldName] = uniqueValues;
      }
    });

    return filterOptions;
  };

  const handleSortChange = (order) => {
    setSortOrder(order);
    const sortedData = [...filteredData].sort((a, b) => {
      if (order === 'new-to-old') {
        return new Date(b.creationDate) - new Date(a.creationDate);
      } else {
        return new Date(a.creationDate) - new Date(b.creationDate);
      }
    });

    setFilteredData(sortedData);
  };

  return {
    data: filterApplied ? filteredData : data,
    filters,
    appliedFilters,
    filterApplied,
    sortOrder,
    setFilters,
    setAppliedFilters,
    applyFilters,
    resetFilters,
    handleSortChange,
    extractFilterOptions,
  };
};

export default useFilterAndSort;
