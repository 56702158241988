// src/components/BagSetSkeletonLoader.js
import React from 'react';
import ContentLoader from 'react-content-loader';

const BagSetSkeletonLoader = () => (
  <ContentLoader 
    speed={2}
    width={400}
    height={300}
    viewBox="0 0 400 300"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="4" ry="4" width="400" height="200" /> {/* Image */}
    <rect x="0" y="210" rx="4" ry="4" width="150" height="15" /> {/* Company */}
    <rect x="0" y="230" rx="4" ry="4" width="100" height="15" /> {/* SKU */}
    <rect x="0" y="250" rx="4" ry="4" width="180" height="15" /> {/* Gamma Treatment */}
    <rect x="0" y="270" rx="4" ry="4" width="120" height="15" /> {/* Product Per Case */}
    <rect x="0" y="290" rx="4" ry="4" width="180" height="15" /> {/* Packaging */}
  </ContentLoader>
);

export default BagSetSkeletonLoader;
