// src/components/Common/CategoryButtons.js
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import categoriesData from '../../data/categories.json'; // Adjust the path as needed

const useStyles = makeStyles((theme) => ({
  button: {
    border: '2px solid #1976D2',
    borderRadius: '20px',
    color: '#1976D2',
    padding: theme.spacing(2),
    width: '100%',
    height: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    margin: theme.spacing(1),
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    '&:hover': {
      backgroundColor: '#1976D2',
      color: '#fff',
      transform: 'scale(1.05)',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonText: {
    fontSize: '1rem', // Increased font size for better readability
    textAlign: 'center',
    fontWeight: 'bold', // Add font weight for emphasis
  },
  heading: {
    marginBottom: theme.spacing(3),
    textAlign: 'center', // Center align the heading for better presentation
    fontSize: '1.25rem', // Increased font size for the heading
    fontWeight: 'bold',
  },
}));

const CategoryButtons = ({ excludedCategoryLink }) => {
  const classes = useStyles();
  // Log the data received through excludedCategoryLink
  console.log('Excluded Category Link:', excludedCategoryLink);

  return (
    <div>
      <Typography variant="h6" className={classes.heading}>
        Interested in Other Sets
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {categoriesData
          .filter(category => category.link !== excludedCategoryLink) // Exclude the selected category
          .map(category => (
            <Grid item key={category.id} xs={12} sm={6} md={4} lg={3}>
              <Link to={`/v1/portal/csutc/dashboard/${category.link}`} className={classes.button}>
                <div className={classes.buttonText}>{category.name}</div>
              </Link>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default CategoryButtons;