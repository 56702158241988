// src/components/Notification/Notification.js
import React from 'react';

const Notification = ({ message, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white text-black p-6 rounded-lg shadow-lg w-11/12 max-w-md">
        <p>{message}</p>
        <button 
          onClick={onClose} 
          className="mt-4 p-2 bg-red-600 text-white rounded"
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default Notification;
