// src/components/Common/InquiryPopup.js
import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, CircularProgress, Alert, AlertTitle, IconButton, Typography } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const InquiryPopup = ({ open, handleClose, selectedSet, setSelectedSetType, selectedSetSKU }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otherType, setOtherType] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(true);
  const [otherSKU, setOtherSKU] = useState('');
  const [note, setNote] = useState('');
  const [confirmation, setConfirmation] = useState(null);
  const [submittedSKU, setSubmittedSKU] = useState(false);
  const [submissionTime, setSubmissionTime] = useState(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [filePreview, setFilePreview] = useState(null);

  useEffect(() => {
    if (open) {
      const initializePopup = async () => {
        setUserLoading(true);
        setContentLoaded(false);
        setFile(null); // Reset the file state
        setFilePreview(null); // Reset the file preview state
        setShowDetails(false); 
        try {
          await Promise.all([
            (async () => {
              try {
                const response = await axios.get(`/client/api/dashboard/set-inquiry-user-data/sku-check/${selectedSetSKU}`);
                setSubmittedSKU(response.data.submitted);
                setSubmissionTime(response.data.submittedAt);
              } catch (error) {
                console.error('Error checking submission status:', error);
              }
            })(),
            (async () => {
              try {
                const response = await axios.get('/client/api/dashboard/set-inquiry-user-data');
                const user = response.data;
                setFirstName(user.firstName || '');
                setLastName(user.lastName || '');
                setEmail(user.email || '');
                setPhoneNumber(user.phoneNumber || '');
              } catch (error) {
                console.error('Error fetching user data:', error);
              } finally {
                setUserLoading(false);
                setContentLoaded(true);
              }
            })()
          ]);
        } catch (error) {
          console.error('Error initializing popup:', error);
          setUserLoading(false);
          setContentLoaded(true);
        }
      };

      initializePopup();
    } else {
      // Reset state when the popup is closed
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhoneNumber('');
      setOtherType('');
      setFile(null);
      setOtherSKU('');
      setNote('');
      setConfirmation(null);
      setSubmittedSKU(false);
      setContentLoaded(false);
    }
  }, [open, selectedSet, setSelectedSetType, selectedSetSKU]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);

    // Show preview for images and PDFs
    if (file) {
      const fileType = file.type;
      if (fileType.includes('image')) {
        setFilePreview(URL.createObjectURL(file));
      } else if (fileType.includes('pdf')) {
        setFilePreview('pdf'); // Indicate that the file is a PDF
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('phoneNumber', phoneNumber);
    formData.append('setType', setSelectedSetType);
    formData.append('setUUID', selectedSet?.uuid || '');
    formData.append('otherType', otherType);
    formData.append('cfdSKU', selectedSetSKU);
    formData.append('cfdImage', selectedSet?.cfdImage || '');
    formData.append('note', note);
    if (file) formData.append('file-upload', file);

    try {
      await axios.post('/client/api/dashboard/set-inquiry', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setConfirmation({ firstName, lastName, email });
      toast.success('Enquiry submitted successfully');
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to submit enquiry');
      }
      console.error('Error submitting enquiry:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      {!userLoading && (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '24px' }}>
          <DialogTitle>Submit Inquiry</DialogTitle>
          {submittedSKU && (
          <Link
          to={`/v1/portal/csutc/dashboard/inquiries/submitted-inquiries`}
          style={{
            textDecoration: 'none',
            color: '#007bff', // Text color matching the background
            backgroundColor: '#f8f9fa', // Light background color
            padding: '6px 12px', // Reduced padding
            borderRadius: '4px', // Slightly rounded corners
            border: '1px solid #007bff', // Blue border
            display: 'inline-block',
            marginTop: '16px',
            fontWeight: 'bold',
            textAlign: 'center',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
            transition: 'background-color 0.3s, box-shadow 0.3s, color 0.3s', // Smooth transitions
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = '#e2e6ea'; // Slightly darker light background on hover
            e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)'; // Darker shadow on hover
            e.currentTarget.style.color = '#0056b3'; // Change text color on hover
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = '#f8f9fa'; // Original light background color
            e.currentTarget.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.1)'; // Original shadow
            e.currentTarget.style.color = '#007bff'; // Original text color
          }}
        >
          See all inquiries
        </Link>
        
          )}
        </div>
       )}
      <DialogContent>
        {userLoading ? (
          <CircularProgress />
        ) : (
          <>
            {submittedSKU ? (
              <div style={{ textAlign: 'center', marginTop: '16px' }}>
                <Alert severity="info" iconMapping={{ info: <InfoIcon fontSize="inherit" /> }}>
                  <AlertTitle>Inquiry Already Submitted</AlertTitle>
                  It looks like you've already submitted an inquiry.<br />
                  If you need further assistance, please contact our support team.<br />
                  <strong>Email:</strong> {email}<br />
                  {submissionTime && (
                    <div>
                      <strong>Submitted At:</strong> {new Date(submissionTime).toLocaleString()}
                    </div>
                  )}
                </Alert>
              </div>
            ) : confirmation ? (
              <div style={{ textAlign: 'center', marginTop: '16px' }}>
                <Alert severity="success" iconMapping={{ success: <CheckCircleIcon fontSize="inherit" /> }}>
                  <AlertTitle>Inquiry Submitted</AlertTitle>
                  Thank you, <strong>{firstName} {lastName}</strong>!<br />
                  We have received your inquiry.<br />
                  <strong>Email:</strong> {email}<br />
                  {submissionTime && (
                    <div>
                      <strong>Submitted At:</strong> {new Date(submissionTime).toLocaleString()}
                    </div>
                  )}
                </Alert>
              </div>
            ) : (
              contentLoaded && (
                <>
                  {selectedSet?.cfdImage && (
                    <div style={{ textAlign: 'center', marginBottom: '16px' }}>
                      <p>You are inquiring for: <strong>{selectedSetSKU} | ({setSelectedSetType})</strong></p>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img 
                          src={selectedSet.cfdImage} 
                          alt={selectedSet?.cfdImageDescription || 'CFD image'} 
                          style={{ maxHeight: '150px', borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} 
                        />
                      </div>
                    </div>
                  )}

                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                    <Typography variant="body2" style={{ marginRight: '8px', fontSize: '0.875rem' }}>
                      Your information will be sent along with the inquiry data.
                    </Typography>
                    <IconButton
                      onClick={() => setShowDetails(!showDetails)}
                      style={{ color: '#1976d2' }}
                    >
                      {showDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      <span style={{ marginLeft: '8px', fontSize: '0.875rem' }}>
                        {showDetails ? 'Hide' : 'Show'} Details
                      </span>
                    </IconButton>
                  </div>

                  {showDetails && (
                    <>
                      <TextField
                        label="First Name"
                        fullWidth
                        margin="normal"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        disabled
                      />
                      <TextField
                        label="Last Name"
                        fullWidth
                        margin="normal"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        disabled
                      />
                      <TextField
                        label="Email"
                        fullWidth
                        margin="normal"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled
                      />
                      <TextField
                        label="Phone Number"
                        fullWidth
                        margin="normal"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        disabled
                      />
                    </>
                  )}

                  <form onSubmit={handleSubmit}>
                    <TextField
                      label="Drawing No"
                      fullWidth
                      margin="normal"
                      value={selectedSet?.cfdSKU || otherSKU}
                      onChange={(e) => setOtherSKU(e.target.value)}
                      disabled={selectedSetSKU !== 'others'}
                    />
                    <TextField
                      label="Set Type"
                      fullWidth
                      margin="normal"
                      value={selectedSet?.type || otherType}
                      onChange={(e) => setOtherType(e.target.value)}
                      disabled={setSelectedSetType !== 'others'}
                    />
                    <TextField
                      label="Note (optional)"
                      fullWidth
                      margin="normal"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      multiline
                      rows={4}
                    />
                   <Typography variant="subtitle1" style={{ marginTop: '16px' }}>
                    File Upload <span style={{ fontSize: '0.8rem', color: '#888' }}>(optional)</span>
                  </Typography>
                  <div style={{ marginTop: '8px', textAlign: 'center' }}>
                    <input
                      accept="image/*,application/pdf"
                      style={{ display: 'none' }}
                      id="file-upload"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <label htmlFor="file-upload">
                      <Button
                        variant="outlined"
                        color="primary"
                        component="span"
                        style={{ marginBottom: '16px' }}
                      >
                        Upload File
                      </Button>
                    </label>
                  </div>
        
                {filePreview && (
                  <div style={{ textAlign: 'center', marginBottom: '16px' }}>
                    {filePreview === 'pdf' ? (
                      <div>
                        <InsertDriveFileIcon style={{ fontSize: 50, color: '#1976d2' }} />
                        <Typography variant="body2">{file.name}</Typography>
                      </div>
                    ) : (
                      <img 
                        src={filePreview} 
                        alt="File preview" 
                        style={{ maxHeight: '150px', borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} 
                      />
                    )}
                  </div>
                )}
                    {loading && <CircularProgress style={{ display: 'block', margin: '16px auto' }} />}
                  </form>
                </>
              )
            )}
          </>
        )}
      </DialogContent>
      {contentLoaded && (
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
          {!confirmation && !submittedSKU && (
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              disabled={loading}
            >
              Submit Inquiry
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default InquiryPopup;

