// src/components/Layout/Layout.js
import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { Outlet } from 'react-router-dom'; // Import Outlet
import Header from '../Header/Header';
import Footer from '../Footer/Footer'; // Import Footer

const Layout = () => {
  return (
    <div className="flex flex-col h-screen">
      <div className="flex flex-1">
        <div className="w-64 z-index-9999"> {/* Set a fixed width for the sidebar */}
          <Sidebar />
        </div>
        <div className="flex-1 flex flex-col">
          <Header />
          <main className="flex-1 overflow-auto p-6 mt-20">
            <Outlet />
          </main>
        </div>
      </div>
      <Footer /> {/* Add Footer here */}
    </div>
  );
};

export default Layout;
