// src/components/StickyBottomPopup/CookiePolicyModal.js
import React from 'react';
import { Modal, Box } from '@mui/material';

const CookiePolicyModal = ({ isOpen, onClose }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box className="bg-white p-6 rounded shadow-lg max-w-lg mx-auto mt-20">
        <h2 className="text-lg font-bold mb-4">Cookie Policy</h2>
        <p className="text-sm">
          [Insert your cookie policy information here.]
        </p>
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
          >
            Close
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default CookiePolicyModal;