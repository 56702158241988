// src/components/BottleManifoldSets/BottleManifoldSets.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import BagSetSkeletonLoader from '../../Skeleton/commonSetSkeletonLoader';
import ImageSkeletonLoader from '../../Common/ImageSkeletonLoader';
import categories from '../../../data/categories.json';
import commonSetFields from '../../../data/commonSetField.json';
import { useTitle } from '../../Contexts/TitleContext';
import { Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Icon } from '@mui/material';
import BusinessIcon from '@mui/icons-material/BusinessOutlined';
import LabelIcon from '@mui/icons-material/LabelOutlined';
import CategoryIcon from '@mui/icons-material/CategoryOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import BackpackOutlinedIcon from '@mui/icons-material/BackpackOutlined';
import FormatSizeOutlinedIcon from '@mui/icons-material/FormatSizeOutlined';
import FlipCameraAndroidOutlinedIcon from '@mui/icons-material/FlipCameraAndroidOutlined';
import EnquiryPopup from '../../Common/InquiryPopup';


const useStyles = makeStyles((theme) => ({
  card: {
    border: '2px solid #3b82f6', // Blue border
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2), // Adds space between the buttons
    marginTop: theme.spacing(2), // Adds some space above the buttons
    marginBottom: theme.spacing(2), // Adds some space above the buttons
  },
  viewDetailsButton: {
    border: '2px solid #3b82f6', // Blue border
    color: '#3b82f6', // Blue text color
    backgroundColor: 'transparent', // No background color
    '&:hover': {
      backgroundColor: '#ebf8ff', // Light blue background on hover
    },
  },
}));

const iconMapping = {
  company: BusinessIcon,
  cfdSKU: LabelIcon,
  gammaTreatment: InfoIcon,
  productPerCase: CategoryIcon,
  packaging: BackpackOutlinedIcon,
  engineerName: EngineeringOutlinedIcon,
  size: FormatSizeOutlinedIcon,
  rev: FlipCameraAndroidOutlinedIcon
};

const BottleManifoldSets = () => {
  const [bottleManifoldSets, setBottleManifoldSets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [imageLoading, setImageLoading] = useState({});
  const [failedImages, setFailedImages] = useState({});
  const [categoryName, setCategoryName] = useState('');
  const [categoryLink, setCategoryLink] = useState('');
  const [skeletonCount, setSkeletonCount] = useState(6);
  const [imagesLoaded, setImagesLoaded] = useState({});
  const [sortOrder, setSortOrder] = useState('new-to-old'); 
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedSetType, setSelectedSetType] = useState('');
  const [selectedSetSKU, setSelectedSetSKU] = useState('');
  const [selectedUUID, setSelectedUUID] = useState('');
  const classes = useStyles();
  const { setTitle } = useTitle();
  const [selectedSet, setSelectedSet] = useState(null);  

  useEffect(() => {
    setTitle('Bottle Manifold Sets');
  }, [setTitle]);

  useEffect(() => {
    const selectedCategory = categories[5];
    setCategoryName(selectedCategory.name);
    setCategoryLink(selectedCategory.link);

    const fetchData = async (link) => {
      try {
        setLoading(true);

        const response = await axios.get(`/client/api/dashboard/sidebar/${link}`);
        const sortedData = sortBottleManifoldSets(response.data, sortOrder);
        setBottleManifoldSets(sortedData);

        const initialImageLoading = response.data.reduce((acc, set) => {
          acc[set.uuid] = true;
          return acc;
        }, {});
        setImageLoading(initialImageLoading);

        const initialImagesLoaded = response.data.reduce((acc, set) => {
          acc[set.uuid] = false;
          return acc;
        }, {});
        setImagesLoaded(initialImagesLoaded);

        setSkeletonCount(Math.max(response.data.length, 6));
      } catch (error) {
        console.error('Error fetching bottle Manifold sets:', error);
        setError('Failed to fetch bottle Manifold sets.');
      } finally {
        setLoading(false);
      }
    };

    if (selectedCategory.link) {
      fetchData(selectedCategory.link);
    }
  }, [sortOrder]);

  const sortBottleManifoldSets = (data, order) => {
    return [...data].sort((a, b) => {
      if (order === 'new-to-old') {
        return new Date(b.creationDate) - new Date(a.creationDate);
      } else {
        return new Date(a.creationDate) - new Date(b.creationDate);
      }
    });
  };

  const handleImageLoad = (uuid) => {
    setImageLoading((prev) => ({ ...prev, [uuid]: false }));
    setImagesLoaded((prev) => ({ ...prev, [uuid]: true }));
  };

  const handleImageError = (e, uuid) => {
    e.target.onerror = null;
    setFailedImages((prev) => ({ ...prev, [uuid]: true }));
    setImagesLoaded((prev) => ({ ...prev, [uuid]: false }));
  };

  const allImagesLoaded = Object.values(imagesLoaded).every(status => status === true);

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
    setBottleManifoldSets((prevBottleManifoldSets) => sortBottleManifoldSets(prevBottleManifoldSets, event.target.value));
  };

  const handleOpenPopup = (setType, uuid) => {
    const selectedSet = bottleManifoldSets.find((s) => s.uuid === uuid); // Use `bottleManifoldSets` instead of `sets`
    setSelectedSetType(setType);
    setSelectedSetSKU(selectedSet?.cfdSKU || '');
    setSelectedUUID(uuid);
    setSelectedSet(selectedSet); // Pass selected set
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };
  
  if (loading) {
    return (
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-black">{categoryName} Customer Facing Drawing</h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {Array.from({ length: skeletonCount }).map((_, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden border">
              <BagSetSkeletonLoader />
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-red-500 py-4">{error}</div>;
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-black">{categoryName} Customer Facing Drawing</h1>
        <div className="flex items-center">
          <span className="text-sm text-gray-600 mr-4">{bottleManifoldSets.length} items</span>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Sort by</InputLabel>
            <Select
              value={sortOrder}
              onChange={handleSortChange}
              label="Sort by"
            >
              <MenuItem value="new-to-old">New to Old</MenuItem>
              <MenuItem value="old-to-new">Old to New</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {bottleManifoldSets.length > 0 ? (
          bottleManifoldSets.map((set) => (
            <div
              key={set.uuid}
              className="bg-white rounded-lg shadow-md overflow-hidden border border-blue-500 hover:shadow-xl transition-shadow duration-300"
            >
              <Link to={`/v1/portal/csutc/dashboard/${categoryLink}/details/${set.uuid}`}>
                <div className="relative">
                  {failedImages[set.uuid] ? (
                    <ImageSkeletonLoader className="w-full h-48" />
                  ) : (
                    <img
                      src={set.cfdImage}
                      alt={`${set.type} image`}
                      className="w-full h-48 object-cover"
                      onLoad={() => handleImageLoad(set.uuid)}
                      onError={(e) => handleImageError(e, set.uuid)}
                      style={{ display: imageLoading[set.uuid] ? 'none' : 'block' }}
                    />
                  )}
                  <div className="absolute top-0 left-0 bg-black bg-opacity-30 text-white p-4">
                    <h2 className="text-xl font-semibold">{set.type}</h2>
                  </div>
                </div>
                <div className="p-4">
                  {commonSetFields.fields.map((field, index) => {
                    const IconComponent = iconMapping[field.field] || Icon;
                    return (
                      <p key={index} className="text-gray-700 mb-2 flex items-center">
                        {IconComponent && <IconComponent className={classes.icon} />}
                       {field.label}:&nbsp;<strong>{set[field.field] || 'N/A'}</strong>
                      </p>
                    );
                  })}
                </div>
              </Link>
              <div className={classes.buttonGroup}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenPopup(set.type, set.uuid)}
              >
                Make Inquiry
              </Button>

              <Link to={`/v1/portal/csutc/dashboard/${categoryLink}/details/${set.uuid}`}>
              <Button variant="contained" className={classes.viewDetailsButton}>
                View Details
              </Button>
            </Link>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center col-span-full py-4 text-gray-500">No items available.</div>
        )}
      </div>
      <EnquiryPopup
        open={popupOpen}
        handleClose={handleClosePopup}
        selectedSet={selectedSet}
        setSelectedSetType={selectedSetType}
        selectedSetSKU={selectedSetSKU}
      />
    </div>
  );
};

export default BottleManifoldSets;


