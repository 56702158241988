import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  media: {
    height: '100%', // Full height
    width: '100%',  // Full width
    backgroundSize: 'contain',
    border: '2px solid #015A9A',
    borderRadius: '5px'
  },
  badge: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    backgroundColor: '#D3D3D3', // Light grey color
    color: theme.palette.text.primary,
    padding: theme.spacing(0.5, 1),
    fontSize: '0.75rem', // Adjust font size if needed
    borderRadius: '12px', // Optional: adjust border radius
  },
  button: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  relatedCard: {
    margin: theme.spacing(1),
  },
  stickyButton: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000, // Ensure it's above other content
    transition: 'opacity 0.3s ease',
  },
  hiddenButton: {
    opacity: 0,
  },
  container: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  heading: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  cardContent: {
    paddingTop: '100px', // Add padding-top
    paddingBottom: '100px!important', // Add padding-bottom
  }
}));

export default useStyles;
