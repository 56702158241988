// src/components/CommonCopyright.js
import React from 'react';
import { Typography, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  copyrightContainer: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
  },
  heading: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: '1.25rem',
    color: theme.palette.secondary.main, // Changed to red color from theme
  },
  paragraph: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    lineHeight: 1.5,
  },
  copyrightHighlight: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  copyrightSymbol: {
    color: theme.palette.text.primary,
  },
}));

const Copyright = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.copyrightContainer}>
      <Typography variant="h6" className={classes.heading}>
        Confidential Property of Foxx Life Sciences
      </Typography>
      <Typography variant="body2" className={classes.paragraph}>
        This document is the property of <span className={classes.copyrightHighlight}>Foxx Life Sciences</span>. The contents are confidential and constitute trade secrets proprietary to <span className={classes.copyrightHighlight}>Foxx Life Sciences</span>. Neither this document nor its content shall be disclosed to any unauthorized person, copied, or published without <span className={classes.copyrightHighlight}>Foxx Life Sciences</span>' prior written consent.
        <br />
        &copy; <span className={classes.copyrightHighlight}>Foxx Life Sciences</span>
      </Typography>
    </Paper>
  );
};

export default Copyright;
