// src/Contexts/WishlistProvider.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { saveToWishlist, removeFromWishlist, fetchWishlist } from '../../utils/wishlistUtils';
import { toast } from 'react-toastify';

const WishlistContext = createContext();

export const WishlistProvider = ({ children }) => {
  const [wishlist, setWishlist] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch wishlist items on component mount
  useEffect(() => {
    const fetchWishlistItems = async () => {
      setLoading(true);
      try {
        const items = await fetchWishlist();
        setWishlist(items);
      } catch (error) {
        console.error('Error fetching wishlist:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchWishlistItems();
  }, []);

  // Function to toggle wishlist status
  const handleWishlistToggle = async (set) => {
    const isWishlisted = wishlist.some(item => item.uuid === set.uuid);

    if (isWishlisted) {
      await removeFromWishlist(set.uuid);
      setWishlist(prev => prev.filter(item => item.uuid !== set.uuid));
      toast.success('Removed from wishlist');
    } else {
      const product = {
        uuid: set.uuid,
        type: set.type,
        categoryLink: set.categoryLink,
        cfdSKU: set.cfdSKU,
        cfdImage: set.cfdImage
      };

      // Save to wishlist and get the updated item with `creationDate`
      const savedItem = await saveToWishlist(product);

      if (savedItem) {
        setWishlist(prev => [...prev, savedItem]);
        toast.success('Added to wishlist');
      }
    }
  };

  return (
    <WishlistContext.Provider value={{ wishlist, handleWishlistToggle, loading }}>
      {children}
    </WishlistContext.Provider>
  );
};

// Custom hook to use the wishlist context
export const useWishlistContext = () => useContext(WishlistContext);