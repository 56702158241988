import React, { useEffect, useState, useCallback } from 'react'; // Import useCallback
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const Logout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [timestamp, setTimestamp] = useState('');
  const [error, setError] = useState(null);

  // Use useCallback to memoize the performLogout function
  const performLogout = useCallback(async (token) => {
    try {
      const response = await axios.get(`/client/api/dashboard/logout?token=${token}`);
      setTimestamp(response.data.timestamp);

      localStorage.removeItem('authToken');
      localStorage.removeItem('logoutRequested'); // Clear the flag

      setLoading(false);
    } catch (error) {
      console.error('Error logging out:', error);
      setError('Failed to log out. Please try again.');
      setLoading(false);
    }
  }, [setError]); // Include setError in the dependency array

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');

    if (localStorage.getItem('logoutRequested') === 'true' && token) {
      performLogout(token);
    } else {
      navigate('/v1/portal/csutc/dashboard', { replace: true });
    }
  }, [location, navigate, performLogout]); // Include dependencies here

  const handleRedirectAndReload = () => {
    navigate('/account-center/signin-identifier', { replace: true });
    window.location.reload();
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      {loading ? (
        <div className="loader">Logging out, please wait...</div>
      ) : (
        <div className="text-center">
          {error ? ( // Conditionally render the error message
            <p className="text-red-500">{error}</p>
          ) : (
            <>
              <p className="text-xl font-semibold">All done! Have a good time 😊</p>
              {timestamp && (
                <p className="text-lg">Logout Time: {new Date(timestamp).toLocaleString()}</p>
              )}
              <button
                onClick={handleRedirectAndReload}
                className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Go back to log in
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Logout;
