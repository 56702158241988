import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { categoryLinks } from '../../utils/categoryLinks';
import './Dashboard.css'; // Ensure this file is up to date with Tailwind or remove if not used
import { useTitle } from '../Contexts/TitleContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Helmet } from 'react-helmet';
import useFavicon from '../../hooks/useFavicon';
import favicon from '../../assets/images/favicon.png'; // Import favicon

const Dashboard = () => {
  useFavicon(favicon); // Use imported favicon
  const [categoriesCount, setCategoriesCount] = useState({}); // State for the counts
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [userFirstName, setUserFirstName] = useState('');
  const [bannerData, setBannerData] = useState([]); // State for the banner data

  const { setTitle } = useTitle();

  useEffect(() => {
    // Check local storage for user data
    const storedUserFirstName = localStorage.getItem('userFirstName');
    if (storedUserFirstName) {
      setUserFirstName(storedUserFirstName);
    } else {
      // Fetch user data from API if not available in local storage
      const fetchUserData = async () => {
        try {
          const response = await axios.get('/client/api/dashboard/user-profile-header');
          const { firstName } = response.data;
          setUserFirstName(firstName);
          // Save user data to local storage
          localStorage.setItem('userFirstName', firstName);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      fetchUserData();
    }
  }, []);

  useEffect(() => {
    // Update title with user name
    if (userFirstName) {
      setTitle(`👋 Welcome, ${userFirstName}`);
    } else {
      setTitle('Home');
    }
  }, [setTitle, userFirstName]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Fetching categories count from /client/api/dashboard/sets");
        const setsResponse = await axios.get('/client/api/dashboard/sets'); // Fetch sets data
        setCategoriesCount(setsResponse.data);
        console.log("Categories count fetched:", setsResponse.data);
        
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Fetch banner data from the backend
    const fetchBannerData = async () => {
      try {
        const response = await axios.get('/client/api/dashboard/get-banners-data');
        setBannerData(response.data);
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching banner data:', error);
        setError('Failed to fetch banners.');
      } finally {
        setLoading(false);
      }
    };

    fetchBannerData();
  }, []);

  if (loading) {
    return <div className="flex justify-center items-center h-screen text-lg">Loading...</div>;
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen text-lg text-red-500">{error}</div>;
  }

  // Create a map to match category IDs with counts
  const countMap = categoryLinks.reduce((acc, category) => {
    acc[category.id] = categoriesCount[category.id.replace('-', '')] || 0;
    return acc;
  }, {});

  
  const placeholderImage = 'https://res.cloudinary.com/dtsg4pipk/image/upload/v1724152627/bioprocess-web-custom-sut-area-cfd-diagrams-placehoolder-image_ms7vmp.png'; // Replace with the actual path to your placeholder image

      // Tracking function
      const trackBannerClick = async (banner) => {
        const clickedAt = new Date().toISOString(); // Get current time in ISO format
        const bannerData = {
            id: banner.id,
            type: banner.type,
            link: banner.link,
            image: banner.imageUrl, // Change this to match the backend field
            clickedAt,
        };
    
        try {
            console.log('Sending banner click tracking request for banner:', bannerData);
            const response = await axios.post('/client/api/dashboard/track-banner-click', bannerData);
            console.log('Banner click tracked successfully:', response.data);
        } catch (error) {
            console.error('Error tracking banner click:', error.response ? error.response.data : error.message);
        }
    };
    
  
  return (
    <div>
        <Helmet>
        <title>Dashboard - Custom Sut Client Area</title>
        <meta name="description" content="Dashboard - Custom Sut Client Area" />
        <meta property="og:title" content="Dashboard - Custom Sut Client Area" />
        <meta property="og:description" content="Dashboard - Custom Sut Client Area" />
        <link rel="icon" href={favicon} /> {/* Use the imported favicon */}
      </Helmet>
    <main className="flex-1 p-6 overflow-auto">
       {/* Slider Section */}
       <section className="mb-8 mx-auto">
       <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Navigation, Pagination, Autoplay]}
          className="mySwiper"
        >
          {bannerData.map((banner) => (
            <SwiperSlide key={banner.id} className="flex justify-center items-center">
              <Link
                to={`${banner.link}?source=slider`}
                className="h-full flex justify-center items-center"
                onClick={() => {
                  console.log(`Clicked on banner with ID: ${banner.id}`);
                  trackBannerClick(banner);
                }}
              >
                <img
                  src={banner.imageUrl}
                  alt={banner.bannerAltText || banner.name}
                  className="h-full object-cover rounded-lg"
                />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
        </section>
      <section className="mt-8">
        <h2 className="text-xl font-semibold mb-4 capitalize md:uppercase">Featured Sets Categories</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {categoryLinks.map((category) => (
            <Link 
              key={category.id} 
              to={`/v1/portal/csutc/dashboard/${category.link}`} 
              className="relative block p-4 border border-[#0171BB] rounded-lg shadow-lg overflow-hidden hover:bg-gray-50 transition-colors"
            >
              <div className="relative w-full" style={{ height: '18rem' }}>
                {/* <div 
                  className="absolute top-2 right-2 bg-[#0071ce] text-white text-xs px-2 py-1 rounded-full shadow-md"
                >
                  {countMap[category.id] || 0}
                </div> */}
                <div 
                  className="w-full h-full bg-cover bg-center rounded-lg"
                  style={{ 
                    backgroundImage: `url(${category.image || placeholderImage})`, 
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center'
                  }}
                >
                </div>
              </div>
              <div className="flex flex-col items-center mt-4 p-2 bg-[#0171BB] text-white rounded-b-lg">
                <h3 className="text-lg font-semibold text-center">{category.name}</h3>
                {/* <p className="text-gray-200 mt-2 text-center">{`Description for ${category.name}`}</p> */}
              </div>
            </Link>
          ))}
        </div>
      </section>

    </main>
    </div>
  );
};

export default Dashboard;
