// src/components/Filters/CategorySlider.js
import React, { useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';  // Import Tooltip from Material UI

const CategorySlider = ({ categories }) => {
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);

  useEffect(() => {
    // Set up the timer to cycle through categories every 5 seconds
    const timer = setInterval(() => {
      setCurrentCategoryIndex(prevIndex => (prevIndex + 1) % categories.length); // Loop through the categories
    }, 5000); // 5000ms = 5 seconds
    
    // Cleanup the interval on unmount
    return () => clearInterval(timer);
  }, [categories.length]);

  return (
    <div className="flex gap-2 ml-2 overflow-x-auto" style={{ maxWidth: '200px'}}>
      {/* Display one category at a time */}
      {categories.map((category, idx) => (
        <Tooltip title={category} key={idx} arrow>
          <strong
            className={`text-blue-700 px-2 py-1 text-s rounded-lg ${idx === currentCategoryIndex ? 'bg-blue-100' : 'bg-transparent'}`}
            style={{
              padding: "4px 8px",
              whiteSpace: 'nowrap', // Prevent labels from wrapping
              overflow: 'hidden', // Ensure text doesn't overflow
              textOverflow: 'ellipsis', // Add ellipsis for overflowed text
              transition: 'background-color 0.3s ease',
              display: idx === currentCategoryIndex ? 'inline-block' : 'none', // Show only the current category
            }}
          >
            {category || 'N/A'}
          </strong>
        </Tooltip>
      ))}
    </div>
  );
};

export default CategorySlider;
