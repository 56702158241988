import React from 'react';
import useRecentlyViewed from '../../hooks/useRecentlyViewed';

const RecentlyViewedButton = ({ set, children }) => {
  const { handleRecentlyViewed } = useRecentlyViewed();

  const handleClick = () => {
    handleRecentlyViewed(set);
  };

  return (
    <div onClick={handleClick} role="button" tabIndex={0} style={{ cursor: 'pointer' }}>
      {children}
    </div>
  );
};

export default RecentlyViewedButton;
