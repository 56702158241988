import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ImageSkeletonLoader from './ImageSkeletonLoader';

const BannerDisplay = ({ selectedSetType }) => {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchBanners = async () => {
      try {
       // console.log(`Fetching banners with type: ${selectedSetType}`); 
        const response = await axios.get(`/client/api/dashboard/get-banners-data/bannertype?type=${selectedSetType}`);
       // console.log(`Response data: ${JSON.stringify(response.data)}`); 
        // Filter banners that have a categoryImageUrl
        const filteredBanners = response.data.filter(banner => banner.categoryImageUrl);
        setBanners(filteredBanners);
      } catch (error) {
        console.error('Error fetching banners:', error);
        setError('Failed to fetch banners.');
      } finally {
        setLoading(false);
      }
    };

    if (selectedSetType) {
      fetchBanners();
    }
  }, [selectedSetType]);

  if (loading) {
    return (
      <div className="p-6">
        <ImageSkeletonLoader className="w-full h-48" />
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-red-500 py-4">{error}</div>;
  }

  return (
    <div className="p-6">
      <div className="space-y-6"> {/* Removed grid layout */}
        {banners.map((banner) => (
          <div key={banner.id} className="bg-white rounded-lg overflow-hidden">
            <img
              src={banner.categoryImageUrl}
              alt={banner.categoryAltText || 'Banner image'}
              className="w-full h-28 object-cover"  // Ensure the image covers the container
              style={{ width: '100%', height: 'auto', objectFit: 'cover' }}  // Ensure full width and aspect ratio
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BannerDisplay;
