// src/components/Common/TabsComponent.js
import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import DOMPurify from 'dompurify'; // Import DOMPurify

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    overflow: 'hidden',
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tabPanel: {
    padding: theme.spacing(3),
  },
  // Custom styles for lists within the tabs
  listStyles: {
    listStyle: 'disc', // Change this to the desired style (e.g., disc, circle, square)
    margin: 0,
    paddingLeft: theme.spacing(2), // Adjust padding as needed
    '& ol, & ul, & menu': { // Scope styles to the lists only within this component
      listStyleType: 'disc', // Set list style type for ordered and unordered lists
      margin: 0,
      padding: 0,
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CustomTabs = ({ description, specification }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Description and Specification Tabs"
        className={classes.tabs}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Description" />
        <Tab label="Specification" />
      </Tabs>
      <TabPanel value={value} index={0} className={classes.tabPanel}>
        <Typography 
          variant="body1" 
          component="div" 
          className={classes.listStyles} // Apply custom styles for lists
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} 
        />
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabPanel}>
        <Typography 
          variant="body1" 
          component="div" 
          className={classes.listStyles} // Apply custom styles for lists
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(specification) || 'Specification data not available.' }} 
        />
      </TabPanel>
    </Paper>
  );
};

CustomTabs.propTypes = {
  description: PropTypes.string.isRequired,
  specification: PropTypes.string.isRequired,
};

export default CustomTabs;
