// src/components/OfflineMessage/OfflineMessage.js
import React from 'react';
import './OfflineMessage.css'; // Optional: for custom styling

const OfflineMessage = ({ onRetry }) => {
  return (
    <div className="offline-message">
      <h2>Connect to the internet</h2>
      <p>You're offline. Check your connection.</p>
      <button onClick={onRetry}>Retry</button>
    </div>
  );
};

export default OfflineMessage;
