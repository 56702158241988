// src/utils/saveRecentlyViewedCards.js
import axios from 'axios';

let viewedProductsCache = new Set();

export const saveRecentlyViewed = async (product) => {
  if (viewedProductsCache.has(product.uuid)) {
    console.log('Product already saved recently:', product.uuid);
    return;
  }

  console.log('Saving recently viewed product:', product);
  try {
    await axios.post('/client/api/dashboard/get-recently-viewed', product);
    viewedProductsCache.add(product.uuid);
    // Remove from cache after 5 minutes to allow re-saving
    setTimeout(() => {
      viewedProductsCache.delete(product.uuid);
    }, 5 * 60 * 1000); // 5 minutes
  } catch (error) {
    console.error('Error saving recently viewed product:', error);
  }
};
