// src/components/Common/GetAllUserInquiries.js
import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Typography, Box, Grid, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import axios from 'axios';
import { useTitle } from '../Contexts/TitleContext';
import { Link } from 'react-router-dom';
import FilePopup from '../../hooks/FilePopup'; // Import the FilePopup component

const GetAllUserInquiries = () => {
  const [inquiries, setInquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle('Submitted Inquiries');
  }, [setTitle]);

  useEffect(() => {
    const fetchInquiries = async () => {
      try {
        const response = await axios.get('/client/api/dashboard/user-submitted-inquiries');
        setInquiries(response.data);
        setTotalCount(response.data.length);
      } catch (error) {
        setError("Failed to fetch inquiries.");
        console.error("Error fetching inquiries:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInquiries();
  }, []);

  const refreshInquiries = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/client/api/dashboard/user-submitted-inquiries');
      setInquiries(response.data);
      setTotalCount(response.data.length);
    } catch (error) {
      setError("Failed to fetch inquiries.");
      console.error("Error fetching inquiries:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewFile = (fileUrl) => {
    setSelectedFileUrl(fileUrl);
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setSelectedFileUrl(null);
  };

  return (
    <Box p={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom>
            Submitted Inquiries Data
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} container justifyContent="flex-end" alignItems="center">
          <Typography variant="subtitle1" gutterBottom style={{ marginRight: '16px' }}>
            Total Inquiries: {totalCount}
          </Typography>
          <IconButton onClick={refreshInquiries} color="primary">
            <RefreshIcon />
          </IconButton>
        </Grid>
      </Grid>
      <TableContainer component={Paper} style={{ marginTop: '16px' }}>
        {loading ? (
          <CircularProgress style={{ display: 'block', margin: '16px auto' }} />
        ) : error ? (
          <p>{error}</p>
        ) : inquiries.length === 0 ? (
          <Typography variant="subtitle1" align="center" style={{ margin: '16px' }}>
            You have not submitted any inquiries yet.
          </Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Set Type</TableCell>
                <TableCell>Drawing No</TableCell>
                <TableCell>Drawing Image</TableCell>
                <TableCell>Submitted At</TableCell>
                <TableCell>Submitted Document</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inquiries.map((inquiry) => (
                <TableRow key={inquiry._id}>
                  <TableCell>{inquiry.setType}</TableCell>
                  <TableCell>
                    <Link to={`/v1/portal/csutc/dashboard/${inquiry.typeLink}/details/${inquiry.uuid}`} style={{ textDecoration: 'none', color: 'blue' }}>
                      {inquiry.cfdSKU}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {inquiry.cfdImage && (
                      <img src={inquiry.cfdImage} alt={inquiry.cfdSKU} style={{ maxHeight: '50px', borderRadius: '8px' }} />
                    )}
                  </TableCell>
                  <TableCell>{new Date(inquiry.submittedAt).toLocaleString()}</TableCell>
                  <TableCell>
                    {inquiry.fileUrl && (
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleViewFile(inquiry.fileUrl)}
                      >
                        View File
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>{inquiry.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {/* Popup Component */}
      <FilePopup open={openPopup} onClose={handleClosePopup} fileUrl={selectedFileUrl} />
    </Box>
  );
};

export default GetAllUserInquiries;
