// src/components/OnlineMessage/OnlineMessage.js
import React from 'react';
import './OnlineMessage.css'; // Optional: for custom styling

const OnlineMessage = ({ onRetry }) => {
  return (
    <div className="online-message">
      <h2>You're back online!</h2>
      <p>Connection restored. Refreshing the page...</p>
    </div>
  );
};

export default OnlineMessage;
