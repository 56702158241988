// src/components/Common/RelatedItems.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Grid, Card, CardContent, CardMedia, Button, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 180,
    backgroundSize: 'contain',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: 'scale(1.01)',
      boxShadow: theme.shadows[6],
    },
  },
  relatedCard: {
    margin: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
  },
  cardContent: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  description: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    borderRadius: theme.shape.borderRadius,
    textTransform: 'capitalize',
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

const RelatedItems = ({ currentUUID, apiEndpoint, title, categoryLink: propCategoryLink }) => {
  const classes = useStyles();
  const [relatedItems, setRelatedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchRelatedItems = async () => {
      try {
        const response = await axios.get(apiEndpoint);
        console.log('API Response:', response.data);

        const items = response.data;

        // Filter out the current item
        const filteredItems = items.filter(item => item.uuid !== currentUUID);

        console.log('Filtered Items:', filteredItems);

        setRelatedItems(filteredItems);
      } catch (error) {
        console.error('Error fetching related items:', error);
        setError('Failed to fetch related items.');
      } finally {
        setLoading(false);
      }
    };

    fetchRelatedItems();
  }, [currentUUID, apiEndpoint]);

  const scrollToDiv = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleLinkClick = (uuid) => {
    const targetId = 'back'; // Replace with your target div ID
    navigate(`/v1/portal/csutc/dashboard/${propCategoryLink.link}/details/${uuid}`);
    setTimeout(() => scrollToDiv(targetId), 0);
  };

  if (loading) {
    return <div className="text-center py-4">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500 py-4">{error}</div>;
  }

  return (
    <div className="mt-6">
      {relatedItems.length > 0 && (
        <>
          <div className="mt-12 mb-6">
            <Divider /> {/* Added Divider */}
          </div>
          <Typography variant="h6" component="div" style={{ marginBottom: '1rem', fontWeight: 'bold' }}>
            {title}
          </Typography>
        </>
      )}
      <Grid container spacing={3}>
        {relatedItems.map((relatedItem, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            <Card className={classes.relatedCard}>
              <CardMedia
                className={classes.media}
                image={relatedItem.cfdImage || 'path/to/placeholder-image.png'}
                title={relatedItem.cfdSKU}
              />
              <CardContent className={classes.cardContent}>
                <div className={classes.description}>
                  <Typography variant="body2" className={classes.title}>
                    <BusinessIcon className={classes.icon} />
                    {relatedItem.company || 'Not available'}
                  </Typography>
                  <Typography variant="body2">
                    <DrawOutlinedIcon className={classes.icon} />
                    {relatedItem.cfdSKU || 'Not available'}
                  </Typography>
                </div>
                <div className={classes.buttonWrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => handleLinkClick(relatedItem.uuid)}
                  >
                    View Details
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default RelatedItems;
