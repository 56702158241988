// src/components/Common/WishlistCard/Wishlist.js
import React, { useEffect, useState } from 'react';
import { useWishlistContext } from '../../Contexts/WishlistProvider';
import { Link } from 'react-router-dom';
import { useTitle } from '../../Contexts/TitleContext';
import { Helmet } from 'react-helmet';
import useFavicon from '../../../hooks/useFavicon';
import favicon from '../../../assets/images/favicon.png';
import { CircularProgress, IconButton, Tooltip, Button, Chip } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const Wishlist = () => {
  useFavicon(favicon);
  const { setTitle } = useTitle();
  const { wishlist, handleWishlistToggle, loading } = useWishlistContext();
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  // Set Page title in header
  useEffect(() => {
    setTitle('CFD Wishlist');
  }, [setTitle]);

  // Load wishlist items on initial render
  useEffect(() => {
    if (!loading) {
      setFilteredItems(wishlist);
    }
  }, [wishlist, loading]);

  // Function to handle category filtering
  const handleCategoryFilter = (category) => {
    setSelectedCategory(category);
    if (category) {
      setFilteredItems(wishlist.filter(item => item.categoryLink === category));
    } else {
      setFilteredItems(wishlist);
    }
  };

  // Get unique categories for filtering
  const uniqueCategories = [...new Set(wishlist.map(item => item.categoryLink))];

  // Format category names
  const formatCategory = (category) => {
    if (!category) return '';
    return category
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  // Format time difference for display
  const formatTimeDifference = (date) => {
    const now = new Date();
    const diffInSeconds = Math.floor((now - new Date(date)) / 1000);
    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    if (hours > 0) return `${hours} hr ago`;
    if (minutes > 0) return `${minutes} min ago`;
    return `${diffInSeconds} sec ago`;
  };

  // Show loader if data is still loading
  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <CircularProgress size={50} />
      </div>
    );
  }

  return (
    <div className="p-4">
      {/* Meta OG Title Favicon load under head */}        
      <Helmet>
        <title>CFD Wishlist</title>
        <meta name="description" content="Custom Sut Client Area | CFD Wishlist" />
        <meta property="og:title" content="Custom Sut Client Area | CFD Wishlist" />
        <meta property="og:description" content="Custom Sut Client Area | CFD Wishlist" />
        <link rel="icon" href={favicon} />
      </Helmet>

      {/* Title with total length count */}
      <h2 className="text-2xl font-semibold mb-6 flex justify-between items-center">
        My Wishlist
        {filteredItems.length > 0 && (
          <span className="text-gray-400 font-normal text-sm">
            {selectedCategory
              ? `Total Wishlist Saved for ${formatCategory(selectedCategory)}`
              : 'Total Wishlist Saved'} 
            <strong> ({filteredItems.length})</strong>
          </span>
        )}
      </h2>

      {/* Category Filter Chips */}
      {uniqueCategories.length > 0 && (
        <div className="mb-4 flex space-x-2 overflow-x-auto">
          <Chip
            label="All"
            onClick={() => handleCategoryFilter('')}
            color={!selectedCategory ? 'primary' : 'default'}
            clickable
          />
          {uniqueCategories.map((category, index) => (
            <Chip
              key={index}
              label={formatCategory(category)}
              onClick={() => handleCategoryFilter(category)}
              color={selectedCategory === category ? 'primary' : 'default'}
              clickable
            />
          ))}
        </div>
      )}

      {/* Wishlist Items */}
      {filteredItems.length > 0 ? (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
          {filteredItems.map((item) => (
            <div
              key={item.uuid}
              className="relative bg-white rounded-md shadow hover:shadow-lg transition-shadow duration-200 overflow-hidden border-2 border-[#0171BB]"
            >
              <Link
                to={`/v1/portal/csutc/dashboard/${item.categoryLink || 'default-category'}/details/${item.uuid}`}
                className="block"
              >
                {/* Image Section */}
                <img
                  src={item.cfdImage}
                  alt={item.type}
                  className="w-full h-40 object-contain"
                />

                {/* Card Content */}
                <div className="p-3 pb-0">
                  <h3 className="text-sm font-semibold">{item.type} Sets</h3>
                  <p className="text-gray-500 text-xs">Drawing No: {item.cfdSKU}</p>
                  <div className="text-xs text-white bg-[#40A766] hover:bg-[#2E9252] px-3 py-1 rounded-lg w-fit mt-2">
                    {item.creationDate ? (
                      `Wishlist Added: ${formatTimeDifference(item.creationDate)}`
                    ) : (
                      'Wishlist Added: Unknown'
                    )}
                  </div>
                </div>
              </Link>

              {/* Action Buttons */}
              <div className="p-3">
                <div className="flex justify-between items-center space-x-2">
                  <Link
                    to={`/v1/portal/csutc/dashboard/${item.categoryLink || 'default-category'}/details/${item.uuid}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Button variant="contained" color="primary" size="small">
                      View CFD Details
                    </Button>
                  </Link>
                  <Tooltip title="Remove from Wishlist">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleWishlistToggle(item);
                      }}
                      size="small"
                    >
                      <DeleteOutlineIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="flex justify-center items-center mt-4 space-x-2">
                  <Link
                    to={`/v1/portal/csutc/dashboard/${item.categoryLink || 'default-category'}`}
                    className="text-[#0171BB] hover:underline"
                    onClick={(e) => e.stopPropagation()}
                  >
                    View all sets drawing
                  </Link>
                </div>
              </div>

              {/* Wishlist Icon Toggle */}
              <div className="absolute top-2 right-2">
                {wishlist.some(w => w.uuid === item.uuid) ? (
                  <FavoriteIcon className="text-red-500" />
                ) : (
                  <FavoriteBorderIcon className="text-gray-400" />
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center text-gray-500 mt-10">No items in your wishlist</div>
      )}
    </div>
  );
};

export default Wishlist;
