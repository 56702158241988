// src/components/Footer/Footer.js
import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
    const currentYear = new Date().getFullYear();
  return (
    <footer className="text-[#969696] py-4 text-center w-full shadow-md z-1">
      <div className="container mx-auto">
        <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body2" component="span" className="text-sm mr-3">
            © {currentYear} foxxbioprocess.com |&nbsp; {/* Use dynamic year here */}
          </Typography>
          <Typography variant="body2" component="span" className="text-sm">
             v1.1.0-client-prod-1 |&nbsp;
           </Typography>
          <Box display="flex" justifyContent="center" alignItems="center" gap="10px" spacing={2}>
            <Link
              href="https://foxxbioprocess.com/pages/contact-us"
              style={{ color: '#0000008a', textDecoration: 'none', display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}
              className="mx-3"
            >
              <ContactMailIcon style={{ color: '#969696', fontSize: '0.875rem' }} className="mr-1" />
              Contact
            </Link>
            <Link
              href="https://www.facebook.com/foxxlifesciences/"
              style={{ color: '#0000008a', textDecoration: 'none', display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}
              className="mx-3"
            >
              <FacebookIcon style={{ color: '#969696', fontSize: '0.875rem' }} className="mr-1" />
              Facebook
            </Link>
            <Link
              href="https://twitter.com/foxxlifescience"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#0000008a', textDecoration: 'none', display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}
              className="mx-3"
            >
              <TwitterIcon style={{ color: '#969696', fontSize: '0.875rem' }} className="mr-1" />
              Twitter
            </Link>
            <Link
              href="https://www.youtube.com/user/foxxlifesciences"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#0000008a', textDecoration: 'none', display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}
              className="mx-3"
            >
              <YouTubeIcon style={{ color: '#969696', fontSize: '0.875rem' }} className="mr-1" />
              YouTube
            </Link>
            <Link
              href="https://www.linkedin.com/company/foxxlifesciences/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#0000008a', textDecoration: 'none', display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}
              className="mx-3"
            >
              <LinkedInIcon style={{ color: '#969696', fontSize: '0.875rem' }} className="mr-1" />
              LinkedIn
            </Link>
          </Box>
        </Box>
      </div>
    </footer>
  );
};

export default Footer;
