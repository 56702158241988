// src/Contexts/WishlistButton.js
import React, { useEffect, useState } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useWishlistContext } from './WishlistProvider';
import { Tooltip, IconButton } from '@mui/material';

const WishlistButton = ({ set }) => {
  const { wishlist, handleWishlistToggle } = useWishlistContext();
  const [isWishlisted, setIsWishlisted] = useState(false);

  // Update the wishlist status whenever the selected set changes
  useEffect(() => {
    setIsWishlisted(wishlist.some(item => item.uuid === set?.uuid));
  }, [wishlist, set]);

  return (
    <Tooltip title={isWishlisted ? "Remove from Wishlist" : "Add to Wishlist"}>
      <IconButton
        onClick={() => handleWishlistToggle(set)}
        style={{
          border: `2px solid ${isWishlisted ? '#0171BB' : '#0171BB'}`,
          borderRadius: '50%',
          padding: '4px',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          backgroundColor: 'transparent'
        }}
        size="small"
      >
        {isWishlisted ? (
          <FavoriteIcon style={{ color: '#0171BB' }} />
        ) : (
          <FavoriteBorderIcon style={{ color: '#0171BB' }} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default WishlistButton;
